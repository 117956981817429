import { isiOS } from "@multimediallc/web-utils/modernizr"
import { EventRouter } from "./events"
import { fullscreenEnabled } from "./fullscreen"
import { RecordedVideo } from "./recordedVideo"
import type Hls from "hls.js"
import type { Events, ManifestLoadedData } from "hls.js"

const VideoJS = window["videojs"]
const HlsObj = window["Hls"] satisfies Hls

export class VideoJSRecordedVideo extends RecordedVideo {
    videojsPlayer: videojs.Player
    videojsActive = false
    activatedEvent = new EventRouter<void>("videoJSActivated")
    opts: videojs.PlayerOptions = {
        techOrder: ["html5"],
        html5: {
            hlsjsConfig: {
                maxMaxBufferLength: 240,
                manifestLoadPolicy: {
                    default: {
                        maxTimeToFirstByteMs: Infinity,
                        maxLoadTimeMs: 20000,
                        timeoutRetry: {
                            maxNumRetry: 2,
                            retryDelayMs: 500,
                            maxRetryDelayMs: 1000,
                        },
                        errorRetry: {
                            maxNumRetry: 2,
                            retryDelayMs: 1000,
                            maxRetryDelayMs: 8000,
                        },
                    },
                },
                playlistLoadPolicy: {
                    default: {
                        maxTimeToFirstByteMs: 10000,
                        maxLoadTimeMs: 20000,
                        timeoutRetry: {
                            maxNumRetry: 3,
                            retryDelayMs: 500,
                            maxRetryDelayMs: 1000,
                        },
                        errorRetry: {
                            maxNumRetry: 3,
                            retryDelayMs: 1000,
                            maxRetryDelayMs: 2000,
                        },
                    },
                },
                fragLoadPolicy: {
                    default: {
                        maxTimeToFirstByteMs: 10000,
                        maxLoadTimeMs: 120000,
                        timeoutRetry: {
                            maxNumRetry: 4,
                            retryDelayMs: 500,
                            maxRetryDelayMs: 1000,
                        },
                        errorRetry: {
                            maxNumRetry: 8,
                            retryDelayMs: 1000,
                            maxRetryDelayMs: 2000,
                        },
                    },
                },
                startFragPrefetch: true,
            },
        },
        // errorDisplay: false,
        fluid: true,
        children: ["mediaLoader", "posterImage", "textTrackDisplay", "bigPlayButton", "controlBar", "errorDisplay", "textTrackSettings", "resizeManager"],
        userActions: { doubleClick: (): void => {} },
        controlBar: {
            children: ["playToggle", "progressControl", "currentTimeDisplay", "timeDivider", "durationDisplay"],
            volumePanel: false,
        },
    }

    constructor(controls: boolean, muted: boolean, audioEnabled = true) {
        super(controls, muted)
        this.player.style.minHeight = ""
        if (this.opts.controlBar === undefined || this.opts.controlBar === false) {
            return
        }
        if (audioEnabled) {
            this.opts.controlBar.children?.push("muteToggle")
            this.opts.controlBar.muteToggle = {}
            if (!isiOS()) {
                this.opts.controlBar.children?.push("volumeControl")
                this.opts.controlBar.volumeControl = {}
            }
        }
        if (fullscreenEnabled() || isiOS()) {
            this.opts.controlBar.children?.push("fullscreenToggle")
        }
        if (muted) {
            this.opts["muted"] = true
        }
        this.player.setAttribute("class", "video-js vjs-big-play-centered vjs-cbcustom") // eslint-disable-line @multimediallc/no-set-attribute
    }

    private activateVideoJS(): void {
        if (this.videojsActive) {
            return
        }
        this.videojsActive = true

        if (VideoJS["Html5Hlsjs"] !== undefined) {
            VideoJS["Html5Hlsjs"]["addHook"]("beforeinitialize", (player: object, hls: Hls): void => {
                // eslint-disable-next-line complexity
                hls.on(HlsObj.Events.MANIFEST_LOADED, (event: Events.MANIFEST_LOADING, data: ManifestLoadedData): void => {
                    debug("MANIFEST_LOADED", data)
                })
                hls.on(HlsObj.Events.MANIFEST_LOADED, () => {
                    hls.startLoad()
                })
                hls.on(HlsObj.Events.LEVEL_SWITCHED, () => {
                    debug("LEVEL_SWITCHED")
                })
            })
        }
        this.videojsPlayer = VideoJS(this.player, this.opts)
        this.videojsPlayer.addClass("vjs-cbcustom")
        // Some browsers under some conditions don't autoplay and won't show the control bar unless forced like this
        this.videojsPlayer.addClass("vjs-has-started")
        this.videojsPlayer.autoplay(true)
        this.activatedEvent.fire(undefined)
        this.videojsPlayer["qualityPickerPlugin"]()
    }

    // also activates videojs
    addSource(videoUrl: string, videoFormat = "video/mp4"): void {
        this.activateVideoJS()
        this.videojsPlayer["src"]({ src: videoUrl, type: videoFormat })
    }

    removeSource(): void {
        if (this.videojsActive) {
            this.videojsPlayer.reset()
        }
    }

    die(): void {
        if (this.videojsActive) {
            this.videojsPlayer.reset()
        }
    }

    play(): void {
        if (!this.videojsActive) {
            return
        }
        const result = this.videojsPlayer.play()
        if (result === undefined) {
            return
        }
        result.catch((e: Error) => {
            switch (e.name) {
                case "AbortError":
                    // Ignore
                    break
                default:
                    error(`DOMError: playing video ${e}`)
                    break
            }
        })
    }

    pause(): void {
        if (this.videojsActive) {
            this.videojsPlayer.pause()
        }
    }
}
