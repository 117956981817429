import { isiOS } from "@multimediallc/web-utils/modernizr"
import { deleteCookie, getCookieOrUndefined } from "@multimediallc/web-utils/storage"
import { VideoJSRecordedVideo } from "../../common/recordedVideoVideoJS"
import { LoadingImage } from "./loadingImage"
import { MediaComponent } from "./mediaComponent"
import { getVideoURLPromise } from "./photoVideoAPI"
import type { IPhotoVideoTabContext, IPhotoVideoTabPhotoset } from "./photoVideoAPI"
import type { IMediaComponentSize } from "./sizing"
import type { RecordedVideo } from "../../common/recordedVideo"

let firstVideo = true

export class MediaVideo extends MediaComponent {
    private video: RecordedVideo
    private videoContainer = document.createElement("div")
    private playing = false
    private videoDataLoaded = false

    private contentLoaded = false

    constructor(photoset: IPhotoVideoTabPhotoset, context: IPhotoVideoTabContext, sizing: IMediaComponentSize, private autoplay = true) {
        super(photoset, context, sizing)
        this.videoContainer.style.display = "none"
        this.videoContainer.style.position = "relative"
        this.videoContainer.style.overflow = "hidden"
        this.mediaSection.appendChild(this.videoContainer)
        this.video = new VideoJSRecordedVideo(true, firstVideo && isiOS(), photoset.videoHasSound)
        firstVideo = false
        this.videoContainer.style.maxWidth = `${this.sizing.maxMediaWidth}px`
        this.videoContainer.style.margin = "0 auto"
        this.videoContainer.style.cursor = "pointer"
        this.video.player.style.backgroundColor = "black"
        this.videoContainer.appendChild(this.video.player)
        if (this.photoset.userCanAccess) {
            this.loadContent()
        }
    }

    repositionChildren(): void {
        super.repositionChildren()
        let vHeight, vWidth: number
        if (this.video instanceof VideoJSRecordedVideo) {
            if (!this.video.videojsActive) {
                return
            }
            vHeight = this.video.videojsPlayer.videoHeight()
            vWidth = this.video.videojsPlayer.videoWidth()
        } else {
            if (!this.videoDataLoaded) {
                return
            }
            vHeight = this.video.player.videoHeight
            vWidth = this.video.player.videoWidth
            this.video.player.style.height = "auto"
            this.video.player.style.width = "100%"
        }
        const aspectRatio = vWidth / vHeight
        let maxWidth: number
        if (vHeight > vWidth) {
            maxWidth = window.innerHeight * aspectRatio - 200
        } else {
            maxWidth = vWidth
        }
        this.videoContainer.style.maxWidth = `${Math.max(maxWidth, 300)}px`
        this.videoContainer.style.height = "auto"
    }

    loadContent(): void {
        if (this.contentLoaded) {
            return
        }
        this.contentLoaded = true
        if (!this.photoset.videoIsReady) {
            const loadingDiv = new LoadingImage(true, true)
            this.addChild(loadingDiv, this.mediaSection)
            return
        }
        const loadingDiv = new LoadingImage( true)
        this.addChild(loadingDiv, this.mediaSection)
        getVideoURLPromise(this.photoset.id).then((url: string) => {
            // COOKIE USE DEPRECATED - remove in 2025
            const replaceCookieWithLocalStorage = (cookieName: string): void => {
                const cookieVal = getCookieOrUndefined(cookieName)
                if (cookieVal !== undefined) {
                    localStorage.setItem(cookieName, cookieVal)
                    deleteCookie(cookieName)
                }
            }
            replaceCookieWithLocalStorage("cbv_vol")
            replaceCookieWithLocalStorage("cbv_mute")
            const cbv_vol = localStorage.getItem("cbv_vol") ?? ".5"
            const cbv_mute = localStorage.getItem("cbv_mute") ?? "0"
            this.replaceHighResLink(url)
            this.video.addSource(url)
            if (this.video instanceof VideoJSRecordedVideo) {
                const vjsPlayer = this.video.videojsPlayer
                this.video.videojsPlayer.on("loadeddata", () => {
                    if (loadingDiv.parent === this) {
                        this.removeChild(loadingDiv)
                    }
                    vjsPlayer.volume(Number(cbv_vol))
                    vjsPlayer.muted(cbv_mute === "1")
                    this.videoContainer.style.display = "block"
                    this.videoDataLoaded = true
                    this.repositionChildren()
                })
                this.video.videojsPlayer.on("contextmenu", (ev) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    return false
                })
                this.video.videojsPlayer.on("volumechange", () => {
                    const vol = vjsPlayer.volume()
                    const muted = vjsPlayer.muted() || vol === 0 ? "1" : "0"
                    localStorage.setItem("cbv_vol", vol.toString())
                    localStorage.setItem("cbv_mute", muted)
                })
                this.video.videojsPlayer.on("play", () => {
                    this.playing = true
                })
                this.video.videojsPlayer.on("pause", () => {
                    this.playing = false
                })
                this.video.videojsPlayer.on("ended", () => {
                    this.playing = false
                })
                if (!this.autoplay) {
                    this.video.videojsPlayer.one("play", () => {
                        this.video.pause()
                    })
                }
                this.video.videojsPlayer.on("error", (e) => {
                    error(`MediaVideo: VideoJs: ${e.message}`)
                })
            } else {
                this.video.player.onloadeddata = () => {
                    this.removeChild(loadingDiv)
                    this.video.player.volume = Number(cbv_vol)
                    this.video.player.muted = cbv_mute === "1"
                    this.videoContainer.style.display = "block"
                    this.videoDataLoaded = true
                    this.repositionChildren()
                }
                this.video.player.oncontextmenu = (ev) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    return false
                }
                this.video.player.onvolumechange = () => {
                    const vol = this.video.player.volume
                    const muted = this.video.player.muted || vol === 0 ? "1" : "0"
                    localStorage.setItem("cbv_vol", vol.toString())
                    localStorage.setItem("cbv_mute", muted)
                }
                this.video.player.onplay = () => {
                    this.playing = true
                }
                this.video.player.onpause = () => {
                    this.playing = false
                }
                this.video.player.onended = () => {
                    this.playing = false
                }
                this.video.player.onerror = (e) => {
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
                    error(`MediaVideo: HTML5: ${e}`)
                }
            }
        }).catch((e) => {
            this.contentLoaded = false
            error(`GetVideoURL: ${e}`)
        })
    }

    playPause(): void {
        if (this.playing) {
            this.video.pause()
        } else {
            this.video.play()
        }
    }
}
