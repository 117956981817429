import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { EventRouter } from "../../common/events"
import { dom } from "../../common/tsxrender/dom"
import { colorClass } from "../colorClasses"
import { resizeDebounceEvent } from "../ui/responsiveUtil"
import { WaterfallDropDown } from "../ui/waterfallDropDown"
import { ConnectedOverlay } from "../ui/waterfallDropDownBase"
import type { DropDownComponent } from "../../common/dropDownComponent"

const dropdownStyle: CSSX.Properties = {
    fontSize: "13px",
    fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
    width: "auto",
    height: "auto",
    position: "absolute",
    display: "none",
    overflow: "",
    cursor: "default",
    top: "0px",
    left: "0px",
}

const containerStyle: CSSX.Properties = {
    width: "149px",
    maxHeight: "450px",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    boxSizing: "border-box",
    padding: "8px",
    borderRadius: "0px 4px 4px 4px",
    WebkitBorderRadius: "0px 4px 4px 4px",
}

export enum ISortingOptions {
    NewestFirst = "Newest First",
    PriceLowToHigh = "Price (low to high)",
    PriceHighToLow = "Price (high to low)",
    TopSellers = "Top Sellers",
    FanclubOnly = "Fan Club Only",
}

export const SortingOptions = [
    ISortingOptions.NewestFirst,
    ISortingOptions.PriceLowToHigh,
    ISortingOptions.PriceHighToLow,
    ISortingOptions.TopSellers,
    ISortingOptions.FanclubOnly,
]

export class PhotoVideosDropdown extends WaterfallDropDown {
    public collectionSetsSort = new EventRouter<{ sortDirection: ISortingOptions, mediaType: string }>("sortCollectionSet")

    constructor(toggleElement: HTMLElement, private mediaType: string) {
        super(toggleElement)
        this.element.dataset.testid = "psDropdown"
        this.element.style.marginTop = "5px"
        this.element.style.boxShadow = "rgb(0 0 0 / 20%) 0px 4px 16px 0px"

        resizeDebounceEvent.listen(() => {
            if (this.isShown()) {
                this.hideElement()
            }
        })
    }

    protected initAddChildren(): void {
        this.connectedOverlay = new PhotoVideosConnectedOverlay()
        this.addChild(this.connectedOverlay)
    }

    protected createBaseElement(tagName: string): HTMLDivElement {
        return <div
            className="psSortingDropdown"
            colorClass={colorClass.tabActiveColor}
            style={dropdownStyle}
        >
            <div className="psSortingContainer"
                style={containerStyle}
                colorClass={[colorClass.tabActiveBgColor, "tabBorderNoHover"]}>
                { this.createOptions() }
            </div>
        </div>
    }

    private createOptions(): HTMLDivElement[] {
        return SortingOptions.map(option => {
            const opts: JSX.HTMLAttributes<HTMLDivElement> = {}
            if (isMobileDevice()) {
                opts["onTouchEnd"] = () => {
                    this.collectionSetsSort.fire({ mediaType: this.mediaType, sortDirection: option })
                }
            }
            return <div
                {...opts}
                className="psSortingOption"
                onClick={() => {
                    this.collectionSetsSort.fire({ mediaType: this.mediaType, sortDirection: option })
                }}
                style={{
                    marginBottom: "1px",
                    padding: "4px",
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontFamily: "UbuntuRegular",
                    cursor: "pointer",
                }}>
                {option}
            </div>
        })
    }
}

class PhotoVideosConnectedOverlay extends ConnectedOverlay {
    public positionAndStyle(toggleElement: HTMLElement, dropDown: DropDownComponent, borderWidthDiff: number, alignRight: boolean): void {
        super.positionAndStyle(toggleElement, dropDown, borderWidthDiff, alignRight)
        this.element.style.backgroundColor = "unset"
    }
}
