import { getScrollingDocumentElement, isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { Component } from "../../common/defui/component"
import { applyStyles, overlap } from "../../common/DOMutils"
import { addPageAction } from "../../common/newrelic"
import { styleUserSelect } from "../../common/safeStyle"
import { i18n } from "../../common/translation"
import { addColorClass } from "../colorClasses"
import { photosetPurchasedEvent } from "./mediaComponent"
import {
    getPhotoVideoContextPromise,
    photoSetDetailRequest,
} from "./photoVideoAPI"
import { PhotoVideoDetail } from "./photoVideoDetail"
import { PhotoVideoPreview } from "./photoVideoPreview"
import { ISortingOptions, PhotoVideosDropdown } from "./photoVideosDropdown"
import { bioRootPadding, cardMargin, cardWidth, fontSize, updateTabDimensions } from "./sizing"
import type {
    IPhotoVideoTabContext,
    IPhotoVideoTabPhotoset,
    IPhotoVideoTabPhotosetsContext, 
} from "./photoVideoAPI"
import type { BoundListener } from "../../common/events"
import type { ITabInstance } from "../../common/roomTabs"

const enum IPhotosetVisibility {
    FanclubOnly = "fanclub_only",
    FanclubOrPurchase = "fanclub_or_purchase",
}
export enum MediaCollectionType {
    Picture = "picture",
    Video = "video",
}

const INITIAL_MAX_SHOW_COUNT = 16

export class PhotoVideos extends Component<HTMLDivElement> implements ITabInstance {
    private detailSection: PhotoVideoDetail

    // state properties region
    private context: IPhotoVideoTabContext
    private photosets: IPhotoVideoTabPhotoset[]
    private room: string

    private initialized = false
    private isShowing = false
    private dataLoading = false

    private pictureSet: PhotoVideoCollection
    private videoSet: PhotoVideoCollection
    // end state properties

    constructor(isCb2 = false) {
        super()

        this.element.id = "PhotoVideos"
        this.element.style.position = "static"
        this.element.style.padding = `${bioRootPadding + 6}px ${bioRootPadding}px ${bioRootPadding}px ${bioRootPadding - cardMargin - 5}px`
        this.element.style.boxSizing = "border-box"
        this.element.className = "photovideos-container"
        this.element.tabIndex = -1

        this.detailSection = new PhotoVideoDetail(isCb2)
        this.detailSection.element.style.paddingLeft = `${cardMargin + 5}px`
        this.detailSection.element.classList.add("photoVideoDetailSection")
        this.detailSection.element.dataset.testid = "photo-video-detail-section"

        const contentsContainer = document.createElement("div")
        contentsContainer.dataset.testid="media-contents"
        this.element.appendChild(contentsContainer)

        styleUserSelect(this.element, "text")
        contentsContainer.appendChild(this.detailSection.element)

        this.videoSet = new PhotoVideoCollection(MediaCollectionType.Video)
        this.videoSet.element.style.position = "relative"
        this.videoSet.element.dataset.testid = "video-set-section"
        this.videoSet.element.style.overflow = "visible"
        contentsContainer.appendChild(this.videoSet.element)

        this.pictureSet = new PhotoVideoCollection(MediaCollectionType.Picture)
        this.pictureSet.element.style.position = "relative"
        this.pictureSet.element.dataset.testid = "picture-set-section"
        contentsContainer.appendChild(this.pictureSet.element)

        photoSetDetailRequest.listen((event) => {
            if (event === undefined) {
                this.detailSection.clearPhotoset("")
            } else {
                this.detailSection.replacePhotoset(event.request.photoset, event.request.context, event.request.autoplay, event.request.scroll)
            }
        })

        photosetPurchasedEvent.listen((id) => {
            this.photosets = this.photosets.map((ps) => {
                if (ps.id === id) {
                    ps.userCanAccess = true
                }
                return ps
            })
            const ps = this.photosets.filter(ps => ps.id === id)[0]
            if (ps.isVideo) {
                this.videoSet.removeOverlay(id)
            } else {
                this.pictureSet.removeOverlay(id)
            }
        })

        addEventListenerPoly("keydown", this.element, (ev: KeyboardEvent) => { // eslint-disable-line complexity
            switch (ev.keyCode) {
                case 37: // left
                    ev.preventDefault()
                    ev.stopPropagation()
                    this.detailSection.previous()
                    break
                case 32: // space
                    ev.preventDefault()
                    this.detailSection.playPause()
                    break
                case 39: // right
                    ev.preventDefault()
                    ev.stopPropagation()
                    this.detailSection.next()
                    break
            }
        })

        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.get("refresh_opener") !== null && window.opener !== null) {
            window.opener.location.reload()
        }
    }

    initRoom(room: string, photoVideosContext?: IPhotoVideoTabPhotosetsContext, id?: number): void {
        this.initialized = true
        this.room = room
        this.detailSection.clearPhotoset()

        this.pictureSet.reset(room)
        this.videoSet.reset(room)

        if (photoVideosContext !== undefined) {
            this.loadContent(id, false, photoVideosContext)
        }
        this.dataLoading = false
        if (this.isShowing) {
            this.loadContent(undefined, false)
        }
    }

    repositionChildren(): void {
        if (this.parent !== undefined) {
            updateTabDimensions(this.parent.element)
        } else if (this.element.parentElement !== null) {
            updateTabDimensions((this.element.parentElement as HTMLDivElement))
        }
        this.pictureSet.repositionChildrenRecursive()
        this.videoSet.repositionChildrenRecursive()
        this.detailSection.repositionChildrenRecursive()
    }

    public show(id?: number): void {
        if (this.isShowing) {
            return
        }
        this.isShowing = true
        addPageAction("PhotosetTabOpened")
        this.element.style.height = ""
        this.element.style.minHeight = "250px"
        this.element.style.width = ""
        this.element.style.padding = `${bioRootPadding + 6}px ${bioRootPadding}px ${bioRootPadding}px ${bioRootPadding - cardMargin - 5}px`
        if (id !== undefined) {
            getScrollingDocumentElement().scrollTop = this.element.offsetTop - 100
        }
        if (!this.dataLoading) {
            this.loadContent(id)
        } else if (id !== undefined) {
            for (const photoset of this.photosets) {
                if (photoset.id === id) {
                    photoSetDetailRequest.fire({
                        request: {
                            photoset: photoset,
                            context: this.context,
                            autoplay: false,
                            scroll: true,
                        },
                        pushState: true,
                    })
                }
            }
        }
    }

    public hide(): void {
        this.isShowing = false
        this.element.style.height = "0px"
        this.element.style.minHeight = ""
        this.element.style.width = "0px"
        this.element.style.padding = "0px"
    }

    public checkBeforeSwitch(switchCallback: () => void): void {
        switchCallback()
    }

    private loadContent(id?: number, scroll = true, photoVideosContext?: IPhotoVideoTabPhotosetsContext): void {
        if (!this.initialized) {
            return
        }
        const room = this.room
        this.dataLoading = true

        this.pictureSet.reset(room)
        this.videoSet.reset(room)

        const loadFromContext = (context: IPhotoVideoTabPhotosetsContext) => {
            if (room !== this.room) {
                return
            }
            this.context = context

            const photos = context.photosets.filter(ps => !ps.isVideo)
            const videos = context.photosets.filter(ps => ps.isVideo)

            this.photosets = context.photosets

            if (this.photosets.length === 0) {
                this.detailSection.clearInfoSection("")
                this.pictureSet.showNoneText()
            } else {
                this.pictureSet.context = this.context
                this.pictureSet.generateCollectionSection(photos)
                this.videoSet.context = this.context
                this.videoSet.generateCollectionSection(videos)
            }

            if (this.photosets.length !== 0) {
                let psToDetail: IPhotoVideoTabPhotoset

                if (id !== undefined && !isNaN(id)) {
                    // Fire either photoset of specified id
                    psToDetail = this.photosets.filter(ps => ps.id === id)[0]
                } else {
                    // Or default photoset that is user accessible
                    const psList = this.photosets.filter(ps => ps.userCanAccess)
                    psToDetail = psList.length > 0 ? psList[0] : this.photosets[0]
                }

                photoSetDetailRequest.fire({
                    request: {
                        photoset: psToDetail,
                        context: this.context,
                        autoplay: false,
                        scroll: scroll,
                    },
                    pushState: true,
                })
            }
        }
        if (photoVideosContext !== undefined) {
            loadFromContext(photoVideosContext)
        } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getPhotoVideoContextPromise(room).then(loadFromContext)
        }
    }

}

export class PhotoVideoCollection extends Component<HTMLDivElement> {
    protected collectionType: MediaCollectionType

    protected titleSection = document.createElement("div")
    protected toggleSort = document.createElement("div")
    protected titleContainer = document.createElement("div")

    private collectionSetDiv = document.createElement("div")
    private collectionSetsContainer: HTMLDivElement | undefined
    private showMore = document.createElement("div")

    protected room: string
    protected sortDirection: ISortingOptions = ISortingOptions.NewestFirst

    public context: IPhotoVideoTabContext
    private collectionsets: IPhotoVideoTabPhotoset[]
    private collectionsetPreviews: PhotoVideoPreview[]
    private psDropdown: PhotoVideosDropdown
    private psDropdownListener: BoundListener<{
        sortDirection: ISortingOptions;
        mediaType: string;
    }>

    private showMoreClicked = false

    constructor(collectionType: MediaCollectionType) {
        super()
        this.collectionType = collectionType
        applyStyles(this.titleContainer, {
            marginTop: collectionType === MediaCollectionType.Picture ? "10px" : "0px",
            paddingLeft: `${cardMargin + 5}px`,
        })

        applyStyles(this.titleSection, {
            textAlign: "left",
            display: "inline-block",
            fontSize: "16px",
            fontWeight: "bold",
        })

        applyStyles(this.toggleSort, {
            paddingLeft: "8px",
            display: "none",
            fontSize: "14px",
            cursor: "pointer",
        })
        this.toggleSort.className = "psToggleSort"
        this.toggleSort.dataset.testid = "psToggleSort"
        this.toggleSort.textContent = `Filter by: ${this.sortDirection}`

        const dropdownArrow = document.createElement("div")
        dropdownArrow.className = "psDropDownArrow"
        this.toggleSort.appendChild(dropdownArrow)

        this.titleContainer.appendChild(this.titleSection)
        this.titleContainer.appendChild(this.toggleSort)
        this.element.appendChild(this.titleContainer)

        this.stylePhotoSetsDiv()
        this.collectionSetDiv.classList.add("photoSetsDiv")
        this.collectionSetDiv.dataset.testid = "photoSetsDiv"
        this.element.appendChild(this.collectionSetDiv)
        if (this.psDropdown === undefined || this.psDropdown === null) {
            this.psDropdown = new PhotoVideosDropdown(this.toggleSort, this.collectionType)
        }
    }

    /**
     * This function is called during jest unit tests to help santiize
     * the DOM testing bed for each test.
     */
    cleanup(): void {
        if (this.psDropdown !== undefined && this.psDropdown !== null) {
            document.body.removeChild(this.psDropdown.element)
        }
    }

    public showNoneText(): void {
        const psContainer = document.createElement("div")
        addColorClass(psContainer, "psContainer")
        psContainer.style.display = "inline-block"
        psContainer.style.padding = "0 5px"
        psContainer.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"

        const noneText = document.createElement("div")
        addColorClass(noneText, "contentText")
        noneText.dataset.testid = "noneText"
        noneText.style.marginLeft = `${cardMargin}px`
        noneText.textContent = i18n.noPicsVideos
        noneText.style.height = "16px"

        psContainer.appendChild(noneText)

        this.collectionSetsContainer = psContainer
        this.collectionSetDiv.appendChild(this.collectionSetsContainer)
    }

    public removeOverlay(id: number): void {
        this.collectionsets = this.collectionsets.map((ps) => {
            if (ps.id === id) {
                ps.userCanAccess = true
            }
            return ps
        })

        for (const psp of this.collectionsetPreviews) {
            if (psp.photoset.id === id) {
                psp.removePurchaseOverlay()
            }
        }
    }

    public reset(room: string): void {
        this.toggleSort.style.display = "none"
        if (this.psDropdown.isShown()) {
            this.psDropdown.hideElement()
        }
        this.room = room

        if (this.psDropdownListener !== undefined && this.psDropdownListener !== null) {
            this.psDropdownListener.removeListener()
        }
        this.removePhotoVideoSection()
        this.replaceTitleSection(true)
    }

    protected initListener(): void {
        this.toggleSort.style.display = "inline-block"
        this.toggleSort.onclick = () => {
            if (isMobileDevice() && overlap(this.psDropdown.element, this.toggleSort)) {
                this.psDropdown.element.style.marginTop = "20px"
            }
        }

        this.psDropdownListener = this.psDropdown.collectionSetsSort.listen(({ sortDirection, mediaType }) => {
            if (mediaType === this.collectionType) {
                this.sortDirection = sortDirection
                this.toggleSort.textContent = `Filter by: ${this.sortDirection}`

                const arrowDown = document.createElement("div")
                arrowDown.classList.add("psDropDownArrow")
                this.toggleSort.appendChild(arrowDown)

                this.sortCollectionSets(this.sortDirection)
            }
        }, false)
    }

    protected sortCollectionSets(sortDirection: ISortingOptions): void { // eslint-disable-line complexity
        this.emptyCollectionSetContainer()
        let collectionsetPreviews: PhotoVideoPreview[] = []

        switch (sortDirection) {
            case ISortingOptions.NewestFirst:
                collectionsetPreviews = this.collectionsetPreviews.sort((a, b) => {
                    const aId = a.photoset.id
                    const bId = b.photoset.id
                    return aId > bId ? -1 : aId < bId ? 1 : 0
                })
                break
            case ISortingOptions.PriceLowToHigh:
                collectionsetPreviews = this.collectionsetPreviews.sort((a, b) => {
                    const aPrice = a.photoset.tokens
                    const bPrice = b.photoset.tokens
                    if (aPrice === 0 && aPrice === bPrice) {
                        const aFanclub = a.photoset.fanClubOnly ? 1 : 0
                        const bFanclub = b.photoset.fanClubOnly ? 1 : 0
                        return aFanclub < bFanclub ? -1 : aFanclub > bFanclub ? 1 : 0
                    }
                    return aPrice < bPrice ? -1 : aPrice > bPrice ? 1 : 0
                })
                break
            case ISortingOptions.PriceHighToLow:
                collectionsetPreviews = this.collectionsetPreviews.sort((a, b) => {
                    const aPrice = a.photoset.tokens
                    const bPrice = b.photoset.tokens
                    if (aPrice === 0 && aPrice === bPrice) {
                        const aFanclub = a.photoset.fanClubOnly ? 1 : 0
                        const bFanclub = b.photoset.fanClubOnly ? 1 : 0
                        return aFanclub > bFanclub ? -1 : aFanclub < bFanclub ? 1 : 0
                    }
                    return aPrice > bPrice ? -1 : aPrice < bPrice ? 1 : 0
                })
                break
            case ISortingOptions.TopSellers:
                collectionsetPreviews = this.collectionsetPreviews.sort((a, b) => {
                    const aPurchaseCount = a.photoset.purchaseCount
                    const bPurchaseCount = b.photoset.purchaseCount
                    return aPurchaseCount > bPurchaseCount ? -1 : aPurchaseCount < bPurchaseCount ? 1 : 0
                })
                break
            case ISortingOptions.FanclubOnly:
                collectionsetPreviews = this.collectionsetPreviews
                    .filter(pv => {
                        const visibility = pv.photoset.visibility

                        if (this.context.hasFanClub) {
                            return visibility === IPhotosetVisibility.FanclubOnly || visibility === IPhotosetVisibility.FanclubOrPurchase
                        } else {
                            return visibility === IPhotosetVisibility.FanclubOnly
                        }
                    })
                    .sort((a, b) => {
                        const aId = a.photoset.id
                        const bId = b.photoset.id
                        return aId > bId ? -1 : aId < bId ? 1 : 0
                    })
                break
            default:
                break
        }

        // Should reset detail view if no content
        if (collectionsetPreviews.length === 0)  {
            photoSetDetailRequest.fire(undefined)
        }

        // Account for pendingApproval or failedApproval
        // and filter into seperate lists
        const nonApproval = collectionsetPreviews.filter(
            (p) => p.photoset.pendingApproval || p.photoset.failedApproval,
        )
        collectionsetPreviews = collectionsetPreviews.filter(
            (p) => !p.photoset.pendingApproval && !p.photoset.failedApproval,
        )

        // Only show failed or approval pending
        if (this.context.isBroadcaster) {
            collectionsetPreviews = collectionsetPreviews.concat(nonApproval)
        }

        if (this.collectionSetsContainer !== undefined) {
            if (!this.showMoreClicked) {
                collectionsetPreviews = collectionsetPreviews.slice(0, INITIAL_MAX_SHOW_COUNT)
            }
            for (const pv of collectionsetPreviews) {
                this.collectionSetsContainer.appendChild(pv.element)
            }
        }

        // In case we hit the filtering option,
        const max = this.collectionsets.length === INITIAL_MAX_SHOW_COUNT ?
            INITIAL_MAX_SHOW_COUNT :
            INITIAL_MAX_SHOW_COUNT - 1
        if (!this.showMoreClicked && collectionsetPreviews.length > max) {
            this.addShowMore()
        }
    }

    public generateCollectionSection(photosets: IPhotoVideoTabPhotoset[]): void { // eslint-disable-line complexity
        this.collectionsetPreviews = []
        this.collectionsets = photosets

        // 1. Create the container
        const psContainer = document.createElement("div")
        addColorClass(psContainer, "psContainer")
        psContainer.style.display = "inline-block"
        psContainer.style.padding = "0 5px"
        psContainer.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"

        // 2. Show initially a max amount of photo
        const max = this.collectionsets.length === INITIAL_MAX_SHOW_COUNT ?
            INITIAL_MAX_SHOW_COUNT :
            INITIAL_MAX_SHOW_COUNT - 1
        for (let i = 0; i < this.collectionsets.length; i++) {
            const photo = this.collectionsets[i]
            const sizing = {
                fontSize: fontSize,
                cardWidth: cardWidth,
                cardMargin: cardMargin,
            }
            const pvPreview = new PhotoVideoPreview(photo, this.context, sizing)
            this.collectionsetPreviews.push(pvPreview)

            if (i < max) {
                psContainer.appendChild(pvPreview.element)
            }
        }

        // 3. Bind the ps container to this class member
        this.collectionSetsContainer = psContainer

        // 4. Add Show More link if the number of media items greater than max
        if (this.collectionsets.length > INITIAL_MAX_SHOW_COUNT) {
            this.addShowMore()
            this.styleShowMore()
        }

        if (this.collectionsets.length > 0) {
            // 5. Add the custom title
            this.replaceTitleSection()
            this.initListener()
        }

        // 6. Add it to current dom
        this.collectionSetDiv.appendChild(psContainer)
        this.sortCollectionSets(this.sortDirection)
    }

    protected replaceTitleSection(empty = false): void {
        if (empty) {
            this.titleSection.textContent = ""
        } else {
            this.titleSection.textContent = this.collectionType === MediaCollectionType.Picture ?
                i18n.roomPicsText(capitalize(this.room)) :
                i18n.roomVidsText(capitalize(this.room))
        }
    }

    private stylePhotoSetsDiv(): void {
        this.collectionSetDiv.style.margin = "9px 0"
        this.collectionSetDiv.style.lineHeight = "15px"
        this.collectionSetDiv.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
    }

    private removePhotoVideoSection(): void {
        if (this.collectionSetsContainer !== undefined) {
            this.collectionSetDiv.removeChild(this.collectionSetsContainer)
            this.collectionSetsContainer = undefined
        }

        this.collectionsetPreviews = []
    }

    private addMorePhotoVideos(): void {
        this.emptyCollectionSetContainer()
        if (this.collectionSetsContainer !== undefined) {
            this.sortCollectionSets(this.sortDirection)
        }
    }

    private styleShowMore(): void {
        this.showMore.style.position = "relative"
        this.showMore.style.display = "inline-block"
        this.showMore.style.width = `${cardWidth}px`
        this.showMore.style.height = "120px"
        this.showMore.style.lineHeight = "100px"
        this.showMore.style.boxSizing = "border-box"
        this.showMore.style.margin = `0px ${cardMargin}px 17px`
        this.showMore.style.textAlign = "center"
        this.showMore.style.overflow = "hidden"
        this.showMore.style.verticalAlign = "middle"
        this.showMore.style.cursor = "pointer"
        this.showMore.onmouseenter = () => {
            this.showMore.style.textDecoration = "underline"
        }
        this.showMore.onmouseleave = () => {
            this.showMore.style.textDecoration = "none"
        }
        this.showMore.onclick = () => {
            this.showMoreClicked = true
            this.removeShowMore()
            this.addMorePhotoVideos()
        }
        const showMoreText = document.createElement("div")
        showMoreText.dataset.testid="show-more"
        addColorClass(showMoreText, "showMoreText")
        showMoreText.style.fontFamily = "UbuntuMedium, Arial, Helvetica, sans-serif"
        showMoreText.style.fontSize = "14px"
        showMoreText.textContent = this.collectionType === MediaCollectionType.Picture ?
            i18n.morePics :
            i18n.moreVideos
        this.showMore.appendChild(showMoreText)
    }

    private removeShowMore(): void {
        if (this.showMore.parentElement === this.collectionSetsContainer) {
            this.collectionSetsContainer.removeChild(this.showMore)
        }
    }

    private addShowMore(): void {
        this.showMoreClicked = false
        if (this.collectionSetsContainer === undefined) {
            return
        }
        this.collectionSetsContainer.appendChild(this.showMore)
    }

    private emptyCollectionSetContainer(): void {
        if (this.collectionSetsContainer !== undefined) {
            let child = this.collectionSetsContainer.lastElementChild
            while (child !== null) {
                this.collectionSetsContainer.removeChild(child)
                child = this.collectionSetsContainer.lastElementChild
            }
        }
    }
}

function capitalize(str: string): string {
    return str.replace(/^\w/, c => c.toUpperCase())
}
