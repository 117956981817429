import { getScrollingDocumentElement } from "@multimediallc/web-utils/modernizr"
import { modalConfirm } from "../../common/alerts"
import { normalizeResource } from "../../common/api"
import { Component } from "../../common/defui/component"
import { i18n } from "../../common/translation"
import { safeWindowOpen } from "../../common/windowUtils"
import { addColorClass } from "../colorClasses"
import { MediaPhotos } from "./mediaPhotos"
import { MediaVideo } from "./mediaVideo"
import { getPhotoSetActionLinks } from "./photoVideoAPI"
import { photoVideoDimensions } from "./sizing"
import type { MediaComponent } from "./mediaComponent"
import type { IPhotoVideoTabContext, IPhotoVideoTabPhotoset } from "./photoVideoAPI"

// popup window options
const height = 768
const width = 850
const left = (screen.width / 2 - width / 2).toString()
const top = (screen.height / 2 - height / 2).toString()
const windowOptions = `resizable,dependent,scrollbars,height=${height},width=${width},top=${top},left=${left}`

export class PhotoVideoDetail extends Component {
    private infoSection = document.createElement("div")
    private actionLinksSection?: HTMLDivElement
    private mediaSection = document.createElement("div")
    private mediaElement: MediaComponent | undefined
    private seen: Record<number, MediaComponent> = {}

    constructor(private isCb2: boolean) {
        super()
        this.element.dataset.testid="photo-video-detail"
        this.element.style.width = `${photoVideoDimensions.detailWidth}px`
        this.element.style.height = "auto"
        this.element.style.minHeight = "10px"
        this.element.style.position = "relative"
        this.element.style.userSelect = "text"
        this.element.style.webkitUserSelect = "text"

        this.infoSection.style.width = "100%"
        this.infoSection.style.marginBottom = "15px"
        this.mediaSection.style.width = "100%"
        this.mediaSection.style.userSelect = "none"
        this.mediaSection.style.webkitUserSelect = "none"
        this.element.appendChild(this.infoSection)
        this.element.appendChild(this.mediaSection)
    }

    repositionChildren(): void {
        this.element.style.width = `${photoVideoDimensions.detailWidth}px`
        if (this.mediaElement !== undefined) {
            this.mediaElement.resize(photoVideoDimensions)
        }
    }

    clearPhotoset(text = "Loading..."): void {
        this.clearInfoSection(text)
        this.clearLinks()
        if (this.mediaElement !== undefined && this.mediaElement.element.parentElement === this.mediaSection) {
            this.mediaSection.removeChild(this.mediaElement.element)
            this.mediaElement = undefined
        }
    }

    replacePhotoset(photoset: IPhotoVideoTabPhotoset, context: IPhotoVideoTabContext, autoplay = true, scroll = true): void {
        this.clearPhotoset()
        this.replaceInfoSection(photoset, context)
        this.addLinks(photoset, context)
        if (this.seen[photoset.id] !== undefined) {
            this.mediaElement = this.seen[photoset.id]
        } else {
            this.mediaElement = photoset.isVideo
                ? new MediaVideo(photoset, context, photoVideoDimensions, autoplay)
                : new MediaPhotos(photoset, context, photoVideoDimensions)
            if (!photoset.legacyVideo) {
                this.seen[photoset.id] = this.mediaElement
            }
        }
        if (this.mediaElement !== undefined) {
            this.addChild(this.mediaElement, this.mediaSection)
            this.mediaElement.repositionChildrenRecursive()
            this.mediaElement.playPause()
            if (scroll) {
                getScrollingDocumentElement().scrollTop = this.element.offsetTop - 100
            }
        }
    }

    clearInfoSection(text = "Loading..."): void {
        this.infoSection.textContent = text
    }

    clearLinks(): void {
        if (this.actionLinksSection !== undefined) {
            while (this.actionLinksSection.firstChild !== null) {
                this.actionLinksSection.removeChild(this.actionLinksSection.firstChild)
            }
        }
    }

    private replaceInfoSection(photoset: IPhotoVideoTabPhotoset, context: IPhotoVideoTabContext): void {
        this.infoSection.textContent = ""
        if (photoset.failedApproval) {
            const failedApproval = document.createElement("div")
            addColorClass(failedApproval, "errorMessage")
            failedApproval.style.padding = "15px"
            failedApproval.style.borderWidth = "1px"
            failedApproval.style.borderStyle = "solid"
            failedApproval.style.borderRadius = "4px"
            failedApproval.style.margin = "5px 0"
            failedApproval.style.fontSize = "18px"
            failedApproval.style.whiteSpace = "pre-line"
            failedApproval.style.textAlign = "center"
            failedApproval.textContent = `${i18n.failedApprovalText}: ${photoset.rejectionNotes}`
            if (context.isBroadcaster) {
                failedApproval.textContent += `\r\n${i18n.removeOffendingItemsText}`
            }
            this.infoSection.appendChild(failedApproval)
        } else if (photoset.videoErrorProcessing) {
            const errorProcessing = document.createElement("div")
            addColorClass(errorProcessing, "errorMessage")
            errorProcessing.style.padding = "15px"
            errorProcessing.style.borderRadius = "4px"
            errorProcessing.style.margin = "5px 0"
            errorProcessing.style.fontSize = "18px"
            errorProcessing.style.whiteSpace = "pre-line"
            errorProcessing.style.textAlign = "center"
            errorProcessing.textContent = `${i18n.videoProcessingError}`
            if (context.isBroadcaster) {
                errorProcessing.textContent += `\r\n${i18n.videoReupload}`
            }
            this.infoSection.appendChild(errorProcessing)
        }
        const title = document.createElement("div")
        title.dataset.testid="media-title"
        addColorClass(title, "title")
        title.style.textAlign = "left"
        title.style.fontSize = "18px"
        title.style.display = "block"
        title.style.fontWeight = "bold"
        const titleText = document.createElement("span")
        titleText.textContent = `${photoset.name}`
        title.appendChild(titleText)
        if (this.isCb2 === true) {
            const newWindowLink = document.createElement("a")
            newWindowLink.title = `${i18n.openInNewWindowText}`
            newWindowLink.href = normalizeResource(`/photo_videos/photoset/detail/${context.room}/${photoset.id}/`)
            newWindowLink.style.marginLeft = "2px"
            newWindowLink.style.padding = "0 5px"
            const newWindowIcon = document.createElement("div")
            addColorClass(newWindowIcon, "popoutIcon")
            newWindowIcon.style.display = "inline-block"
            newWindowIcon.style.height = "15px"
            newWindowIcon.style.width = "15px"
            newWindowIcon.style.position = "relative"
            newWindowIcon.style.top = "2px"
            newWindowLink.appendChild(newWindowIcon)
            newWindowLink.onmouseenter = () => {
                newWindowLink.style.textDecoration = "underline"
            }
            newWindowLink.onmouseleave = () => {
                newWindowLink.style.textDecoration = "none"
            }
            newWindowLink.onclick = (ev) => {
                ev.preventDefault()
                safeWindowOpen(newWindowLink.href, "_blank", windowOptions)
            }
            title.appendChild(newWindowLink)
        }
        this.infoSection.appendChild(title)
        if (photoset.isVideo && !photoset.videoIsReady && !photoset.videoErrorProcessing) {
            const videoProcessing = document.createElement("div")
            addColorClass(videoProcessing, "videoProcessing")
            videoProcessing.style.textAlign = "left"
            videoProcessing.style.fontSize = "14px"
            videoProcessing.style.display = "block"
            videoProcessing.style.marginTop = "15px"
            videoProcessing.style.marginBottom = "15px"
            videoProcessing.style.wordWrap = "break-word"
            videoProcessing.textContent = i18n.videoBeingProcessed
            this.infoSection.appendChild(videoProcessing)
        }
        const description = document.createElement("div")
        description.dataset.testid="media-description"
        description.style.textAlign = "left"
        description.style.fontSize = "14px"
        description.style.display = "block"
        description.style.marginTop = "15px"
        description.style.marginBottom = "15px"
        description.style.wordWrap = "break-word"
        description.style.whiteSpace = "pre-line"
        description.textContent = `${photoset.description}`
        this.infoSection.appendChild(description)
    }

    private addLinks(photoset: IPhotoVideoTabPhotoset, context: IPhotoVideoTabContext): void {
        if (context.isBroadcaster || context.isStaff) {
            if (this.actionLinksSection === undefined) {
                this.actionLinksSection = document.createElement("div")
                this.actionLinksSection.style.margin = "5px 0"
                this.element.appendChild(this.actionLinksSection)
            }
            getPhotoSetActionLinks(photoset.id).then(html => {
                const linksSection = this.actionLinksSection as HTMLDivElement
                linksSection.innerHTML = html // eslint-disable-line @multimediallc/no-inner-html
                if (this.isCb2) {
                    const broadcasterLinks = linksSection.querySelectorAll<HTMLAnchorElement>("#broadcaster_links a, #staff_links a")
                    for (const link of broadcasterLinks) {
                        link.onclick = (ev: MouseEvent) => {
                            ev.preventDefault()
                            safeWindowOpen(link.href, undefined, windowOptions)
                        }
                    }
                }
                const deleteButton = linksSection.querySelector<HTMLInputElement>("#staff_delete_photoset_button")
                const deleteForm = linksSection.querySelector<HTMLFormElement>("#staff_delete_photoset")
                const enableAudioButton = linksSection.querySelector<HTMLInputElement>("#staff_unmute_video_button")
                const enableAudioForm = linksSection.querySelector<HTMLFormElement>("#staff_unmute_video")
                if (deleteButton !== null && deleteForm !== null) {
                    deleteButton.onclick = () => {
                        modalConfirm("Are you sure you want to remove this item?", () => {
                            deleteForm.submit()
                        })
                    }
                }
                if (enableAudioButton !== null && enableAudioForm !== null) {
                    enableAudioButton.onclick = () => {
                        modalConfirm("Are you sure you want to unmute?", () => {
                            enableAudioForm.submit()
                        })
                    }
                }
            }).catch(() => {})
        }
    }

    previous(): void {
        if (this.mediaElement !== undefined) {
            this.mediaElement.previous()
        }
    }

    next(): void {
        if (this.mediaElement !== undefined) {
            this.mediaElement.next()
        }
    }

    playPause(): void {
        if (this.mediaElement !== undefined) {
            this.mediaElement.playPause()
        }
    }
}
