import { isIE } from "@multimediallc/web-utils/modernizr"
import { normalizeResource } from "../../common/api"
import { addPhotoSetClickedPageAction } from "../../common/bioContent"
import { Component } from "../../common/defui/component"
import { DivotPosition } from "../../common/divot"
import { i18n } from "../../common/translation"
import { addColorClass } from "../colorClasses"
import { buildTooltip } from "../ui/tooltip"
import {
    buildPhotosetLabel,
    FREE,
    photoSetDetailRequest,
} from "./photoVideoAPI"
import type {
    IPhotoVideoTabContext,
    IPhotoVideoTabPhotoset, 
} from "./photoVideoAPI"
import type { IPhotoVideoPreviewSizing } from "./sizing"

export class PhotoVideoPreview extends Component {
    lockOverlay: HTMLDivElement | undefined
    badge: HTMLSpanElement | undefined
    private anchor = document.createElement("a")

    constructor(public photoset: IPhotoVideoTabPhotoset, private context: IPhotoVideoTabContext, private sizing: IPhotoVideoPreviewSizing) {
        super()
        this.element.style.position = "relative"
        this.element.style.display = "inline-block"
        this.element.style.width = `${this.sizing.cardWidth}px`
        this.element.style.height = "120px"
        this.element.style.boxSizing = "border-box"
        this.element.style.margin = `0px ${this.sizing.cardMargin}px ${sizing.cardMargin + 1}px`
        this.element.style.verticalAlign = "middle"
        this.element.style.overflow = "visible"
        this.element.dataset.testid = "photo-video-preview"
        // Disable title on IE11 to prevent link title tooltip
        // from overlapping with no-sound-icon tooltip
        if (!isIE()) {
            this.element.title = photoset.name
        }
        this.element.onmouseenter = () => {
            this.element.style.cursor = "pointer"
            this.element.style.textDecoration = "underline"
        }
        this.element.onmouseleave = () => {
            this.element.style.cursor = "default"
            this.element.style.textDecoration = "none"
        }

        this.anchor.href = normalizeResource(`/photo_videos/photoset/detail/${this.context.room}/${this.photoset.id}/`)
        this.anchor.onclick = (ev: MouseEvent) => {
            if (ev.metaKey || ev.ctrlKey) {
                return
            }
            ev.preventDefault()
            addPhotoSetClickedPageAction(photoset, "PVTab")
            photoSetDetailRequest.fire({
                request: {
                    photoset: this.photoset,
                    context: this.context,
                    autoplay: true,
                    scroll: true,
                },
                pushState: true,
            })
        }
        this.element.appendChild(this.anchor)

        const preview = document.createElement("img")
        preview.dataset.testid = "photo-video-preview-image"
        addColorClass(preview, "previewBorder")
        preview.src = this.photoset.coverUrl
        preview.width = 150
        preview.height = 100

        preview.style.borderWidth = "1px"
        preview.style.borderStyle = "solid"
        preview.style.borderRadius = "3%"

        preview.style.display = "block"
        this.anchor.appendChild(preview)
        if (!this.photoset.userCanAccess || this.context.isBroadcaster) {
            this.addPurchaseOverlay()
        } else if (this.photoset.userCanAccess && this.photoset.userHasPurchased) {
            this.addPurchasedBadge()
        }

        if (this.photoset.isVideo) {
            const videoIcon = document.createElement("img")
            videoIcon.src = `${STATIC_URL}video.svg`
            videoIcon.height = 18
            videoIcon.style.position = "absolute"
            videoIcon.style.top = "8px" // padding + border
            videoIcon.style.right = "8px" // padding + border
            videoIcon.dataset.testid = "videoIcon"
            this.anchor.appendChild(videoIcon)

            if (!this.photoset.videoHasSound && !this.photoset.pendingApproval) {
                const noSoundIcon = document.createElement("img")
                noSoundIcon.src = `${STATIC_URL}no-audio.svg`
                noSoundIcon.height = 18
                noSoundIcon.style.position = "absolute"
                noSoundIcon.style.top = "8px" // padding + border
                noSoundIcon.style.right = "32px" // padding + border
                noSoundIcon.dataset.testid = "noSoundIcon"

                const toolTip = buildTooltip({
                    content: i18n.audioRemovedText,
                    hasHTML: false,
                    width: 175,
                    divotPosition: DivotPosition.Bottom,
                    divotLeftOrTop: "90px",
                })
                toolTip.style.textAlign = "center"
                toolTip.dataset.testid = "noSoundTooltip"

                const span = document.createElement("span")
                toolTip.appendChild(span)
                this.anchor.appendChild(toolTip)

                noSoundIcon.onmouseenter = () => {
                    toolTip.style.display = "block"
                    toolTip.style.left = "12px"
                    toolTip.style.top = "-50px"
                }
                noSoundIcon.onmouseleave = () => {
                    toolTip.style.display = "none"
                }
                this.anchor.appendChild(noSoundIcon)
            }
        }

        const title = document.createElement("div")
        title.dataset.testid="title"
        addColorClass(title, "link")
        title.textContent = this.photoset.name
        title.style.fontSize = `${this.sizing.fontSize}px`
        title.style.left = "0px"
        title.style.maxWidth = "145px"
        title.style.whiteSpace = "nowrap"
        title.style.textOverflow = "ellipsis"
        title.style.overflow = "hidden"
        title.style.lineHeight = "16px"
        this.anchor.appendChild(title)
    }

    private addPurchasedBadge(): void {
        // Token baddge creation
        const purchased = document.createElement("span")
        purchased.dataset.testid = "purchased-badge"
        addColorClass(purchased, "purchasedBadge")
        purchased.style.position = "absolute"
        purchased.style.top = "80px"
        purchased.style.right = "8px"
        purchased.style.padding = "1px 3px"
        purchased.style.lineHeight = "12px"
        purchased.style.borderRadius = "2px"
        purchased.style.fontSize = "9px"
        purchased.textContent = i18n.purchasedCAPS
        this.anchor.appendChild(purchased)
    }

    private addPurchaseOverlay(): void { // eslint-disable-line complexity
        const labelInfo = buildPhotosetLabel(this.photoset, this.context.hasFanClub)
        if (labelInfo.labelType === FREE) {
            return
        }

        this.lockOverlay = document.createElement("div")
        this.lockOverlay.style.height = "101px"
        this.lockOverlay.style.width = "151px"
        this.lockOverlay.style.borderRadius = "3%"
        addColorClass(this.lockOverlay, "lockOverlayBg")
        addColorClass(this.lockOverlay, "rgba")

        this.lockOverlay.style.position = "absolute"
        this.lockOverlay.style.top = "0px"
        this.lockOverlay.style.left = "0px"

        const locked: HTMLImageElement = document.createElement("img")
        const lockImgDim = 18
        locked.src = `${STATIC_URL}lock.svg`
        locked.width = lockImgDim
        locked.height = lockImgDim
        locked.style.marginLeft = `${-lockImgDim / 2}px`
        locked.style.marginTop = `${-lockImgDim / 2}px`
        locked.style.position = "absolute"
        locked.style.left = "50%"
        locked.style.top = "48%"
        locked.style.padding = "4px"
        locked.style.border = "none"
        locked.dataset.testid = "lock-icon"
        this.lockOverlay.appendChild(locked)

        this.anchor.appendChild(this.lockOverlay)

        if (this.photoset.labelText !== "") {
            // Token baddge creation
            this.badge = document.createElement("span")
            addColorClass(this.badge, "tokenText")
            this.badge.innerText = this.photoset.labelText
            this.badge.style.backgroundColor = this.photoset.labelColor
            this.badge.style.color = "#fff"
            this.badge.style.position = "absolute"
            this.badge.style.bottom = "18px"
            this.badge.style.right = "0px"
            this.badge.style.margin = "8px"
            this.badge.style.fontSize = "9px"
            this.badge.style.padding = "1px 3px"
            this.badge.style.lineHeight = "12px"
            this.badge.style.borderRadius = "2px"
            this.badge.dataset.testid = "token-badge"
            this.anchor.appendChild(this.badge)
        }
    }

    removePurchaseOverlay(): void {
        if (this.lockOverlay !== undefined && this.lockOverlay.parentElement === this.anchor) {
            if (this.badge !== undefined && this.badge.parentElement === this.anchor) {
                this.anchor.removeChild(this.badge)
            }
            this.anchor.removeChild(this.lockOverlay)
            this.addPurchasedBadge()
        }
    }
}
