export const bioRootPadding = 15
export const fontSize = 14
export const cardWidth = 152
export const cardMargin = 8
const cardTotalWidth = cardWidth + 2 * cardMargin

export const PURCHASE_BLOCK_WIDTH = 350
export const PURCHASE_BLOCK_HEIGHT = 240
export const MAX_PHOTO_HEIGHT = 1100

const baseDetailWidth = 704
export const photoVideoDimensions = {
    detailWidth: baseDetailWidth,
    maxMediaWidth: baseDetailWidth,
    maxPhotoWidth: baseDetailWidth - 104,
    placeholderHeight: 396,
}

export function updateTabDimensions(div: HTMLElement): void {
    const divWidth = div.offsetWidth
    photoVideoDimensions.detailWidth = Math.max(460,
        cardTotalWidth * Math.floor((divWidth - bioRootPadding * 2 + cardMargin * 2) / cardTotalWidth) - 2 * cardMargin)
    photoVideoDimensions.maxMediaWidth = Math.min(photoVideoDimensions.detailWidth, 1400)
    photoVideoDimensions.maxPhotoWidth = photoVideoDimensions.maxMediaWidth - 104
    photoVideoDimensions.placeholderHeight = Math.floor(photoVideoDimensions.maxMediaWidth * 9 / 16)
}

export interface IMediaComponentSize {
    detailWidth: number
    maxMediaWidth: number
    maxPhotoWidth: number
    placeholderHeight: number
}

export interface IPhotoVideoPreviewSizing {
    fontSize: number
    cardWidth: number
    cardMargin: number
}
