import { ArgJSONMap } from "@multimediallc/web-utils"
import { modalAlert, modalConfirm } from "../../common/alerts"
import { normalizeResource, postCb } from "../../common/api"
import { isAnonymous } from "../../common/auth"
import { Component } from "../../common/defui/component"
import { EventRouter } from "../../common/events"
import { addPageAction } from "../../common/newrelic"
import { loginOverlayRequest } from "../../common/theatermodelib/userActionEvents"
import { i18n } from "../../common/translation"
import { addColorClass } from "../colorClasses"
import { pageContext } from "../interfaces/context"
import { popUpTokenPurchaseModal } from "../ui/tipping"
import {
    buildPhotosetLabel,
    FANCLUB_ONLY,
    FANCLUB_OR_PURCHASE,
    PURCHASE_ONLY,
} from "./photoVideoAPI"
import { PURCHASE_BLOCK_HEIGHT, PURCHASE_BLOCK_WIDTH } from "./sizing"
import type {
    IPhotoVideoTabContext,
    IPhotoVideoTabPhotoset, 
} from "./photoVideoAPI"
import type { IMediaComponentSize } from "./sizing"

export const photosetPurchasedEvent = new EventRouter<number>("photosetPurchasedEvent")

export abstract class MediaComponent extends Component {
    protected mediaSection = document.createElement("div")
    protected bottomSection = document.createElement("div")
    protected lockOverlay: HTMLDivElement | undefined
    private lockPurchaseBlock: HTMLDivElement | undefined
    protected hiResLink = document.createElement("a")

    protected constructor(protected readonly photoset: IPhotoVideoTabPhotoset, protected readonly context: IPhotoVideoTabContext,
        protected sizing: IMediaComponentSize) {
        super()
        this.element.style.display = "block"
        this.element.style.position = "relative"
        this.element.style.width = "100%"
        this.element.style.height = "auto"

        this.mediaSection.style.width = "100%"
        this.mediaSection.style.height = "auto"
        this.mediaSection.style.minHeight = "200px"
        this.element.appendChild(this.mediaSection)

        this.bottomSection.style.width = "100%"
        this.bottomSection.style.height = "40px"
        this.bottomSection.style.position = "relative"
        const createdAt = document.createElement("div")
        createdAt.dataset.testid = "created-at"
        addColorClass(createdAt, "createdAt")
        createdAt.style.height = "20px"
        createdAt.style.lineHeight = "20px"
        createdAt.style.position = "absolute"
        createdAt.style.top = "0px"
        createdAt.style.right = "8px"
        createdAt.style.fontSize = "12px"
        createdAt.style.textAlign = "right"
        createdAt.style.marginTop = "2px"
        createdAt.textContent = `${i18n.uploadedText} ${this.photoset.created}`
        this.bottomSection.appendChild(createdAt)
        if (this.photoset.isVideo && !this.photoset.videoHasSound && !this.photoset.pendingApproval) {
            const audioMutedText = document.createElement("div")
            audioMutedText.style.height = "20px"
            audioMutedText.style.lineHeight = "20px"
            audioMutedText.style.position = "absolute"
            audioMutedText.style.top = "0px"
            audioMutedText.style.left = "8px"
            audioMutedText.style.fontSize = "12px"
            audioMutedText.style.textAlign = "left"
            audioMutedText.style.marginTop = "2px"
            audioMutedText.textContent = `${i18n.audioRemovedText}`
            this.bottomSection.appendChild(audioMutedText)
        }
        addColorClass(this.hiResLink, "link")
        this.hiResLink.style.display = "none"
        this.hiResLink.style.height = "20px"
        this.hiResLink.style.lineHeight = "20px"
        this.hiResLink.style.position = "absolute"
        this.hiResLink.style.top = "0px"
        this.hiResLink.style.left = "0px"
        this.hiResLink.style.fontSize = "12px"
        this.hiResLink.style.textAlign = "left"
        this.hiResLink.style.marginTop = "2px"
        this.hiResLink.target = "_blank"
        this.hiResLink.dataset.testid = "hi-res-link"
        this.hiResLink.onmouseenter = () => {
            this.hiResLink.style.textDecoration = "underline"
        }
        this.hiResLink.onmouseleave = () => {
            this.hiResLink.style.textDecoration = "none"
        }
        if (!this.photoset.isVideo) {
            this.hiResLink.textContent = i18n.hiResImage
        }
        this.bottomSection.appendChild(this.hiResLink)
        this.element.appendChild(this.bottomSection)

        if (!this.photoset.userCanAccess) {
            this.addLockOverlay()
        }
    }

    public abstract loadContent(): void

    public next(): void { }

    public previous(): void { }

    public playPause(): void { }

    public resize(sizing: IMediaComponentSize): void {
        this.sizing = sizing
        if (this.lockOverlay !== undefined && this.lockOverlay.parentElement === this.mediaSection) {
            this.lockOverlay.style.width = `${this.sizing.maxPhotoWidth}px`
            this.lockOverlay.style.height = `${this.sizing.placeholderHeight}px`
            if (this.lockPurchaseBlock !== undefined) {
                this.lockPurchaseBlock.style.left = `${(this.sizing.maxPhotoWidth - PURCHASE_BLOCK_WIDTH) / 2}px`
                this.lockPurchaseBlock.style.top = `${(this.sizing.placeholderHeight - PURCHASE_BLOCK_HEIGHT) / 2}px`
            }
        }
    }

    private confirmLoggedIn(notLoggedInMessage: string): void {
        if (isAnonymous()) {
            if (pageContext.current.isMobile) {
                loginOverlayRequest.fire(false)
            } else {
                modalConfirm(notLoggedInMessage, () => {
                    loginOverlayRequest.fire(false)
                })
            }
        }
    }

    private purchasePhotoset(): void {
        if (isAnonymous()) {
            loginOverlayRequest.fire(false)
            return
        } else if (pageContext.current.isNoninteractiveUser) {
            modalAlert(i18n.internalStaffPurchase)
            return
        }
        modalConfirm(this.photoset.isVideo ? i18n.purchaseVideoText(this.photoset.tokens) : i18n.purchasePhotosText(this.photoset.numPhotos, this.photoset.tokens), () => {
            postCb(`photo_videos/purchase_photoset/${this.photoset.id}/`, { "pvTab": "True" })
                .then((xhr) => {
                    const response = new ArgJSONMap(xhr.responseText)
                    if (response.getBoolean("success")) {
                        addPageAction("PhotosetPurchased", { "photoset_id": this.photoset.id })
                        photosetPurchasedEvent.fire(this.photoset.id)
                        this.photoset.userCanAccess = true
                        this.removeLockOverlay()
                        this.loadContent()
                    } else if (response.getStringOrUndefined("error") !== undefined) {
                        if (response.getBoolean("tokens_error")) {
                            popUpTokenPurchaseModal(
                                `${response.getString("error")}  ${i18n.pressPurchaseTokens}`,
                                pageContext.current.PurchaseEventSources["TOKEN_SOURCE_PURCHASE_MEDIA"])
                        } else {
                            popUpTokenPurchaseModal(response.getString("error"), pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"])
                        }
                    } else {
                        // This may occur if the photoset was purchased in another window
                        modalAlert(this.photoset.isVideo ? i18n.purchasedVideo : i18n.purchasedPhotoSet)
                    }
                })
                .catch((xhr) => {error("Purchase Error", xhr)})
        })
    }

    private joinFanClub(): void {
        if (isAnonymous()) {
            loginOverlayRequest.fire(false)
            return
        } else if (!this.context.hasFanClub) {
            modalAlert(i18n.fanClubDisabledText(this.context.room))
            return
        } else if (pageContext.current.isNoninteractiveUser) {
            modalAlert(i18n.internalStaffFanClub)
            return
        }
        modalConfirm(i18n.joinFanClubPage(this.context.room), () => {
            addPageAction("PhotosetJoinFanclubClicked", { "photoset_id": this.photoset.id })
            window.location.href = normalizeResource(`/fanclub/join/${this.context.room}/`)
        })
    }

    private removeLockOverlay(): void {
        if (this.lockOverlay !== undefined && this.lockOverlay.parentElement === this.mediaSection) {
            this.mediaSection.removeChild(this.lockOverlay)
        }
    }

    private addLockOverlay(): void {
        this.lockOverlay = document.createElement("div")
        this.lockOverlay.style.position = "relative"
        this.lockOverlay.style.width = `${this.sizing.maxPhotoWidth}px`
        this.lockOverlay.style.height = `${this.sizing.placeholderHeight}px`
        this.lockOverlay.style.margin = "0 auto"
        this.lockOverlay.style.overflow = "hidden"
        this.lockOverlay.style.zIndex = "2"
        const lockCover = document.createElement("img")
        lockCover.src = this.photoset.coverUrl
        lockCover.style.width = "100%"
        lockCover.style.height = "100%"
        lockCover.style.filter = "blur(8px)"
        this.lockOverlay.appendChild(lockCover)
        this.lockPurchaseBlock = this.purchaseBlock()
        this.lockOverlay.appendChild(this.lockPurchaseBlock)
        this.mediaSection.appendChild(this.lockOverlay)
    }

    private purchaseBlock(): HTMLDivElement {
        const main = document.createElement("div")
        main.style.width = `${PURCHASE_BLOCK_WIDTH}px`
        main.style.height = `${PURCHASE_BLOCK_HEIGHT}px`
        main.dataset.testid = "media-purchase-block"
        addColorClass(main, "lockOverlayBg")
        addColorClass(main, "rgba")

        main.style.borderRadius = "10px"
        main.style.position = "absolute"
        main.style.left = `${(this.sizing.maxPhotoWidth - PURCHASE_BLOCK_WIDTH) / 2}px`

        main.style.top = `${(this.sizing.placeholderHeight - PURCHASE_BLOCK_HEIGHT) / 2}px`
        main.style.textAlign = "center"
        const textBox = document.createElement("div")
        textBox.style.width = "100%"
        const unlockText = document.createElement("div")
        addColorClass(unlockText, "previewText")
        unlockText.style.fontSize = "16px"
        unlockText.style.height = "40px"
        unlockText.dataset.testid = "unlock-text"
        unlockText.textContent = this.photoset.isVideo ? i18n.unlockVideoText(formatDuration(this.photoset.videoDuration)) : i18n.unlockPhotosText(this.photoset.numPhotos)
        textBox.appendChild(unlockText)
        const labelInfo = buildPhotosetLabel(this.photoset, this.context.hasFanClub)
        let textBoxHeight = 40
        switch (labelInfo.labelType) {
            case FANCLUB_OR_PURCHASE:
                const fanclub = this.createButton(i18n.joinFanClubTextCAPS(formatUsername(this.context.room)), () => {
                    this.joinFanClub()
                })
                const or = document.createElement("div")
                addColorClass(or, "previewText")
                or.textContent = i18n.orSimple
                or.style.fontSize = "14px"
                or.style.height = "30px"
                or.style.lineHeight = "30px"
                or.style.marginBottom = "1px"
                const tip = this.createButton(`${this.photoset.tokens} ${i18n.tokenOrTokensTextCAPS(this.photoset.tokens)}`, () => {
                    this.purchasePhotoset()
                }, true)
                textBox.appendChild(fanclub)
                textBox.appendChild(or)
                textBox.appendChild(tip)
                textBoxHeight += 80
                break
            case FANCLUB_ONLY:
                const fanclub2 = this.createButton(i18n.joinFanClubTextCAPS(formatUsername(this.context.room)), () => {
                    this.joinFanClub()
                })
                textBox.appendChild(fanclub2)
                textBoxHeight += 25
                break
            case PURCHASE_ONLY:
                const tip2 = this.createButton(`${this.photoset.tokens} ${i18n.tokenOrTokensTextCAPS(this.photoset.tokens)}`, () => {
                    this.purchasePhotoset()
                })
                textBox.appendChild(tip2)
                textBoxHeight += 25
        }
        if (this.photoset.isVideo && !this.photoset.videoHasSound && !this.photoset.pendingApproval) {
            const audioMutedText = document.createElement("div")
            addColorClass(audioMutedText, "previewText")
            audioMutedText.style.fontSize = "14px"
            audioMutedText.style.height = "40px"
            audioMutedText.style.lineHeight = "54px"
            audioMutedText.style.marginBottom = "1px"
            audioMutedText.textContent = `${i18n.audioRemovedText}`
            textBox.appendChild(audioMutedText)
        }
        textBox.style.position = "relative"
        textBox.style.top = `${(PURCHASE_BLOCK_HEIGHT - textBoxHeight) / 2 - 5}px`
        main.appendChild(textBox)
        return main
    }

    protected replaceHighResLink(url: string): void {
        if (!this.photoset.isVideo && url !== "") {
            if (!isAnonymous()) {
                this.hiResLink.href = normalizeResource(url)
                this.hiResLink.style.display = "block"
            } else {
                this.hiResLink.href = "#"
                this.hiResLink.style.display = "block"
                this.hiResLink.onclick = (ev) => {
                    ev.preventDefault()
                    this.confirmLoggedIn(i18n.loggedInToViewHiRes)
                }
            }
        }
    }

    private createButton(text: string, onClick: () => void, grey = false): HTMLDivElement {
        const button = document.createElement("div")
        button.dataset.testid = "unlock-button"
        button.textContent = text
        button.style.width = "250px"
        button.style.lineHeight = "25px"
        button.style.borderWidth = "1px"
        button.style.borderStyle = "solid"
        button.style.borderRadius = "4px"
        button.style.fontSize = "12px"
        button.style.textAlign = "center"
        button.style.cursor = "pointer"
        button.onclick = onClick
        addColorClass(button, "previewText")
        addColorClass(button, "buttonBgColor")
        addColorClass(button, "buttonBorder")
        addColorClass(button, "rgba")

        addColorClass(button, "gradient")
        if (grey) {
            addColorClass(button, "grey")
        }
        button.style.position = "relative"
        button.style.left = "50px"
        return button
    }
}

export function formatUsername(username: string): string {
    if (username.length <= 18) {
        return username.toUpperCase()
    }
    const u2 = username.substr(0, 15)
    return `${u2}...`.toUpperCase()
}

function formatDuration(dur: number): string {
    return `${dur > 60 ? `${i18n.minutes(Math.floor(dur / 60))} ` : ""}${i18n.seconds(dur % 60)}`
}
