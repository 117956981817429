import { Component } from "../../common/defui/component"
import { currentSiteSettings } from "../siteSettings"
import { photoVideoDimensions } from "./sizing"

export class LoadingImage extends Component<HTMLDivElement> {
    loadingImg = document.createElement("img")

    constructor (private isVideo = false, noLoading = false) {
        super()
        const height = photoVideoDimensions.placeholderHeight
        const width = this.isVideo ? photoVideoDimensions.maxMediaWidth : photoVideoDimensions.maxPhotoWidth
        this.element.style.position = "relative"
        this.element.style.width = `${width}px`
        this.element.style.height = `${height}px`
        this.element.style.margin = "0 auto"
        this.element.dataset.testid = "media-placeholder-image"
        if (!currentSiteSettings.isWhiteLabel) {
            this.element.style.backgroundImage = `url("${STATIC_URL}no_thumbnail.svg")`
            this.element.style.backgroundRepeat = "no-repeat"
            this.element.style.backgroundSize = "cover"
            this.element.style.webkitBackgroundSize = "cover"
            this.element.style.backgroundPosition = "center"
        }
        
        if (!noLoading) {
            this.loadingImg.style.width = "30px"
            this.loadingImg.style.height = "30px"
            this.loadingImg.style.position = "absolute"
            this.loadingImg.style.top = "50%"
            this.loadingImg.style.left = "50%"
            this.loadingImg.style.transform = "translate(-50%, -50%)"
            this.loadingImg.src = `${STATIC_URL}loading.gif`
            this.loadingImg.dataset.testid = "loading-spinner"
            this.element.appendChild(this.loadingImg)
        }
    }

    repositionChildren(): void {
        super.repositionChildren()
        const height = photoVideoDimensions.placeholderHeight
        const width = this.isVideo ? photoVideoDimensions.maxMediaWidth : photoVideoDimensions.maxPhotoWidth
        this.element.style.width = `${width}px`
        this.element.style.height = `${height}px`
    }
}
