import { isIE } from "@multimediallc/web-utils/modernizr"

export class RecordedVideo {
    player = document.createElement("video")
    source = document.createElement("source")
    loadedData = false

    constructor(controls: boolean, muted: boolean) {
        this.player.style.minHeight = "135px"
        this.player.style.cursor = "pointer"
        this.player.style.margin = "auto"
        this.player.dataset.testid = "user-video"
        this.player.oncontextmenu = (ev: MouseEvent) => {
            ev.preventDefault()
            return false
        }
        this.player.setAttribute("webkit-playsinline", "") // eslint-disable-line @multimediallc/no-set-attribute
        this.player.setAttribute("playsinline", "") // eslint-disable-line @multimediallc/no-set-attribute
        this.player.setAttribute("preload", "auto") // eslint-disable-line @multimediallc/no-set-attribute
        this.player.setAttribute("autoplay", "true") // eslint-disable-line @multimediallc/no-set-attribute
        this.player.controls = controls
        this.player.muted = muted
    }

    addSource(videoUrl: string, videoFormat = "video/mp4"): void {
        this.source = document.createElement("source")
        this.source.type = videoFormat
        this.source.src = videoUrl
        this.player.appendChild(this.source)
        this.player.load()
    }

    removeSource(): void {
        if (this.source.parentElement === this.player) {
            this.source.src = ""
            this.player.removeChild(this.source)
        }
    }

    play(): void {
        function playErrorCallback(e: Error): void {
            switch (e.name) {
                case "AbortError":
                    // Ignore
                    break
                default:
                    error(`DOMError: playing video ${e}`)
                    break
            }
        }
        if (!isIE()) {
            this.player.play().then(() => {
            }).catch(playErrorCallback)
        } else {
            try {
                this.player.play()  // eslint-disable-line @typescript-eslint/no-floating-promises
            } catch (e) {
                playErrorCallback(e)
            }
        }
    }

    pause(): void {
        this.player.pause()
    }
}
