import { ArrowContainers } from "./arrowContainers"
import { LoadingImage } from "./loadingImage"
import { MediaComponent } from "./mediaComponent"
import { getPhotoURLsPromise } from "./photoVideoAPI"
import { MAX_PHOTO_HEIGHT } from "./sizing"
import type { IPhotoVideoTabContext, IPhotoVideoTabPhotoset } from "./photoVideoAPI"
import type { IMediaComponentSize } from "./sizing"

interface IImage {
    domEl: HTMLDivElement
    loaded: boolean
    url: string
}

export class MediaPhotos extends MediaComponent {
    private arrowContainers: ArrowContainers

    private images: IImage[] = []
    private index = 0

    private imageCircles: HTMLImageElement[] = []
    private imageCirclesDiv = document.createElement("div")
    private activeDotIndex = 0

    private contentLoaded = false

    constructor(photoset: IPhotoVideoTabPhotoset, context: IPhotoVideoTabContext, sizing: IMediaComponentSize) {
        super(photoset, context, sizing)
        this.styleArrows()
        this.styleImageCircles()
        this.bottomSection.appendChild(this.imageCirclesDiv)
        if (this.photoset.userCanAccess) {
            this.loadContent()
        }
        window.setTimeout(() => {
            this.repositionChildren()
        }, 0)
    }

    loadContent(): void {
        if (this.contentLoaded) {
            return
        }
        this.contentLoaded = true
        const createPlaceholderImage = () => {
            return {
                domEl: new LoadingImage().element,
                loaded: false,
                url: "",
            }
        }
        while (this.images.length < this.photoset.numPhotos) {
            this.images.push(createPlaceholderImage())
        }
        this.mediaSection.appendChild(this.images[0].domEl)
        this.repositionChildren()
        getPhotoURLsPromise(this.photoset.id).then((urls: string[]) => {
            if (urls.length !== this.photoset.numPhotos) {
                this.photoset.numPhotos = urls.length
                this.styleImageCircles()
                this.showHideArrows()
            }
            while (urls.length > this.images.length) {
                this.images.push(createPlaceholderImage())
            }
            while (urls.length < this.images.length) {
                this.images.pop()
            }
            for (let i = 0; i < urls.length; i += 1) {
                this.replaceLoadingImage(urls[i], i)
            }
            this.replaceHighResLink(urls[0])
        }).catch((e) => {
            this.contentLoaded = false
            error("GetPhotoURLs", e)
        })
        this.showHideArrows()
    }

    replaceLoadingImage(url: string, index: number): void {
        const imageObj = this.images[index]
        imageObj.url = url
        const imgEl = document.createElement("img")
        imgEl.dataset.testid = "media-photo"
        imgEl.style.height = "100%"
        imgEl.style.width = "100%"
        imgEl.src = url
        imgEl.dataset.testid = "user-photo"
        imgEl.onload = () => {
            imageObj.loaded = true
            let width, height: number
            if (imgEl.naturalWidth > imgEl.naturalHeight) {
                width = Math.min(imgEl.naturalWidth, this.sizing.maxPhotoWidth)
                height = Math.floor(width * imgEl.naturalHeight / imgEl.naturalWidth)
            } else {
                height = Math.min(imgEl.naturalHeight, this.sizing.maxPhotoWidth, MAX_PHOTO_HEIGHT)
                width = Math.floor(height * imgEl.naturalWidth / imgEl.naturalHeight)
            }
            imageObj.domEl.style.background = "none"
            imageObj.domEl.style.width = `${width}px`
            imageObj.domEl.style.height = `${height}px`
            while (imageObj.domEl.firstChild !== null) {
                imageObj.domEl.removeChild(imageObj.domEl.firstChild)
            }
            imageObj.domEl.appendChild(imgEl)
            this.repositionChildren()
        }
    }

    repositionChildren(): void {
        super.repositionChildren()
        const repositionArrows = (height: number) => {
            this.arrowContainers.repositionArrows(height)
            this.imageCirclesDiv.style.left = `${(this.sizing.detailWidth - this.imageCirclesDiv.offsetWidth) / 2}px`
        }
        const currImg = this.images[this.index]
        if (currImg === undefined) {
            repositionArrows(this.mediaSection.offsetHeight)
            return
        }
        if (!currImg.loaded) {
            const loading = currImg.domEl.firstChild as HTMLImageElement
            currImg.domEl.style.width = `${this.sizing.maxPhotoWidth}px`
            currImg.domEl.style.height = `${this.sizing.placeholderHeight}px`
            loading.style.top = `${(this.sizing.placeholderHeight - 30) / 2}px`
            loading.style.left = `${(this.sizing.maxPhotoWidth - 30) / 2}px`
            repositionArrows(this.sizing.placeholderHeight)
            return
        }
        const image = currImg.domEl.firstChild as HTMLImageElement
        let width: number, height: number
        if (image.naturalWidth > image.naturalHeight) {
            width = Math.min(image.naturalWidth, this.sizing.maxPhotoWidth)
            height = Math.floor(width * image.naturalHeight / image.naturalWidth)
        } else {
            height = Math.min(image.naturalHeight, this.sizing.maxPhotoWidth, MAX_PHOTO_HEIGHT, window.innerHeight)
            width = Math.floor(height * image.naturalWidth / image.naturalHeight)
        }
        currImg.domEl.style.width = `${width}px`
        currImg.domEl.style.height = `${height}px`
        repositionArrows(height)
    }

    next(): void {
        if (this.photoset.numPhotos < 2 || !this.photoset.userCanAccess) {
            return
        }
        const oldIndex = this.index
        this.index = (this.index + 1) % this.photoset.numPhotos
        this.changeActiveCircle()
        this.changeActiveImage(oldIndex)
    }

    previous(): void {
        if (this.photoset.numPhotos < 2 || !this.photoset.userCanAccess) {
            return
        }
        const oldIndex = this.index
        if (this.index === 0) {
            this.index = this.photoset.numPhotos - 1
        } else {
            this.index -= 1
        }
        this.changeActiveCircle()
        this.changeActiveImage(oldIndex)
    }

    private changeActiveImage(oldIndex: number): void {
        const currImg = this.images[oldIndex]
        if (currImg.domEl.parentElement === this.mediaSection) {
            this.mediaSection.removeChild(currImg.domEl)
        }
        const newImg = this.images[this.index]
        this.mediaSection.appendChild(newImg.domEl)
        this.replaceHighResLink(newImg.url)
        this.repositionChildren()
    }

    private showHideArrows(): void {
        if (this.photoset.numPhotos < 2) {
            this.arrowContainers.hideArrows()
        } else {
            this.arrowContainers.showArrows()
        }
    }

    private styleArrows(): void {
        this.arrowContainers = new ArrowContainers(
            (ev: MouseEvent) => {this.previous()},
            (ev: MouseEvent) => {this.next()},
            this.photoset.userCanAccess)
        this.showHideArrows()
        this.mediaSection.appendChild(this.arrowContainers.leftContainer)
        this.mediaSection.appendChild(this.arrowContainers.rightContainer)
    }

    private bigCircle(c: HTMLImageElement): void {
        c.style.height = "8px"
        c.style.width = "8px"
        c.style.padding = "2px"
    }

    private mediumCircle(c: HTMLImageElement): void {
        c.style.height = "6px"
        c.style.width = "6px"
        c.style.padding = "3px"
    }

    private smallCircle(c: HTMLImageElement): void {
        c.style.height = "4px"
        c.style.width = "4px"
        c.style.padding = "4px"
    }

    private styleImageCircles(): void {
        this.imageCircles = []
        while (this.imageCirclesDiv.firstChild !== null) {
            this.imageCirclesDiv.removeChild(this.imageCirclesDiv.firstChild)
        }
        const maxCircles = Math.min(5, this.photoset.numPhotos)
        if (maxCircles < 2) {
            return
        }
        this.imageCirclesDiv.style.width = `${maxCircles * 12}px`
        this.imageCirclesDiv.style.height = "15px"
        this.imageCirclesDiv.style.position = "absolute"
        this.imageCirclesDiv.style.marginTop = "2px"
        this.imageCirclesDiv.style.top = "0px"
        for (let i = 0; i < maxCircles; i += 1) {
            const circle = document.createElement("img")
            if (this.photoset.numPhotos <= 5) {
                this.bigCircle(circle)
            } else if (i === 3 && maxCircles === 5) {
                this.mediumCircle(circle)
            } else if (i === 4 && maxCircles === 5) {
                this.smallCircle(circle)
            } else {
                this.bigCircle(circle)
            }
            circle.style.display = "inline-block"
            if (i === 0) {
                circle.src = `${STATIC_URL}dot-blue.svg`
            } else {
                circle.src = `${STATIC_URL}dot-gray.svg`
            }
            this.imageCircles.push(circle)
            this.imageCirclesDiv.appendChild(circle)
        }
    }

    private changeActiveCircle(): void { // eslint-disable-line complexity
        if (this.imageCircles.length < 2) {
            return
        }
        this.imageCircles[this.activeDotIndex].src = `${STATIC_URL}dot-gray.svg`
        if (this.index === this.photoset.numPhotos - 1) {
            this.activeDotIndex = Math.min(this.imageCircles.length - 1, 4)
        } else {
            this.activeDotIndex = Math.min(this.index, 3)
        }
        this.imageCircles[this.activeDotIndex].src = `${STATIC_URL}dot-blue.svg`
        if (this.photoset.numPhotos <= 5) {
            for (const c of this.imageCircles) {
                this.bigCircle(c)
            }
            return
        }
        switch (this.activeDotIndex) {
            case 0:
            case 1:
            case 2:
                this.restyleActiveFirstThreeDots()
                break
            case 3:
                this.restyleActiveFourthDot()
                break
            case 4:
                this.restyleActiveFifthDot()
                break
        }
    }

    private restyleActiveFirstThreeDots(): void {
        for (let i = 0; i < this.imageCircles.length; i += 1) {
            const c = this.imageCircles[i]
            if (i < 3) {
                this.bigCircle(c)
            } else if (i === 3) {
                this.mediumCircle(c)
            } else {
                this.smallCircle(c)
            }
        }
    }

    private restyleActiveFourthDot(): void { // eslint-disable-line complexity
        for (let i = 0; i < this.imageCircles.length; i += 1) {
            const c = this.imageCircles[i]
            if (this.index === this.photoset.numPhotos - 2 && i === 4) {
                this.bigCircle(c)
            } else if (this.index === 3 && i === 0 || i === 4 || (this.index !== 3 && i === 1 || i === 4)) {
                this.mediumCircle(c)
            } else if (this.index !== 3 && i === 0) {
                this.smallCircle(c)
            } else {
                this.bigCircle(c)
            }
        }
    }

    private restyleActiveFifthDot(): void {
        for (let i = 0; i < this.imageCircles.length; i += 1) {
            const c = this.imageCircles[i]
            if (i === 0) {
                this.smallCircle(c)
            } else if (i === 1) {
                this.mediumCircle(c)
            } else {
                this.bigCircle(c)
            }
        }
    }
}
